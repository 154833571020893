<template>
  <HeroButtonAction type="button" variant="primary" @click="googleLogin">
    Log in with Google
  </HeroButtonAction>
</template>

<script>
import HeroButtonAction from '@/views/components/form/buttons/HeroButtonAction.vue'

export default {
  components: {
    HeroButtonAction,
  },
  methods: {
    async googleLogin() {
      try {
        const googleUser = await this.$gAuth.signIn()
        const basicProfile = googleUser.getBasicProfile()
        const authResponse = googleUser.getAuthResponse()

        const data = {
          id: googleUser.getId(),
          email: basicProfile.getEmail(),
          accessToken: authResponse.access_token,
          idToken: authResponse.id_token,
        }

        this.$emit('success', data)
      } catch (error) {
        this.$emit('fail', error)
      }
    },
  },
}
</script>
